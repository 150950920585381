import React from 'react';
import { HeaderOne } from "master-react-lib";
import Logo from '../../assets/imgs/DEENCONNECT_WebsiteForMasjid_ColorLogo@2x.png';
import HeaderTopBar from './HeaderTopBar'
import MenuIcon from './menuIcon.svg';
import CloseIcon from './closeIcon.svg';





function Header () {

    const menuData = [
        {
            label: "HOME",
            link: "/#home",
        },
        {
            label: "INTEGRATIONS",
            link: "/#integration",
        },
        {
            label: "FEATURES",
            link: "/#features",
        },
        {
            label: "OUR WORK",
            link: "/#our-work",
        },
        {
            label: "WHY US?",
            link: "/#why-us",
        },
        {
            label: "CONTACT US",
            link: "/contact-us",
        },

    ];

    return (
        <div id="dynamic">
            <HeaderTopBar />
            <HeaderOne
                logo={Logo}
                logoLink="/#home"
                MenuIcon={MenuIcon}
                CloseIcon={CloseIcon}
                background="#fff"
                maxWidth="1175px"
                LogoSize="220px"
                menuData={menuData}

                //// Button Style ////
                buttonLebel="GET A QUOTE"
                ButtonLink="/get-quote"
                path="/get-quote"
                arrow={false}
                arrowColor="#fff"
                buttoncolor="#2E4BF2"
                width="auto"
                height="47px"
                border="#ccc 0px solid"
                borderRadius="4px"
                colorlabel="#fff"
                fontWeight="600"
                textAlign="center"

            />
        </div>
    );
}


export default Header;