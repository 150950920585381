import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import GreenArrow from '../assets/imgs/GreenArrow.svg'
import PriceCrose from '../assets/imgs/Price_CroseIcon.png'


const useStyles = makeStyles((theme) => ({

    inputStyle: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E4E8EC",
      borderWidth: "1px",
      
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E4E8EC",
      borderWidth: "2px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2A8853",
      borderWidth: "1px",
    },
  
    "& .MuiOutlinedInput-input": {
      color: "#0B132B",
      borderWidth: "2px",
      background:"#fff",
      borderRadius:"4px",
    },
  
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#0B132B",
      borderWidth: "1px",
    },
    "& .MuiInputLabel-outlined": {
      color: "#0B132B",
      borderWidth: "2px",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#0B132B",
      borderWidth: "1px",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#0B132B",
      borderWidth: "1px",
    },
  
    "& .MuiFormHelperText-root": {
      position:"absolute",
      bottom:"-21px",
    },
  
    "& .MuiOutlinedInput-input ": {
      padding:"18.5px 14px",
      color:"#0B132B"
    },
  
    
  },
  
 
  
  }));


function BookingForm() {
    const classes = useStyles();
    return (

        <div className="GreenBG MainSection">
            
            <Container maxWidth="lg">
                <Typography variant="h1" className="TitleSmall WhiteText AlignCenter">
                    Masjid website built for today. <span className="priceOff"><img src={PriceCrose} /> Not 1999.</span>
                </Typography>
                <Typography variant="h1" className="SubTitle WhiteText AlignCenter">
                     Need a website ready and secured for digital age, Youths, mobile devices…?<br/> Let’s get started building one for your masjid
                </Typography>

                <div className="BookingFormHome">

                    <TextField 
                        id="outlined-basic" 
                        className={classes.inputStyle} 
                        variant="outlined" 
                        placeholder="Full name"
                    />

                    <TextField 
                        id="outlined-basic" 
                        className={classes.inputStyle} 
                        variant="outlined" 
                        placeholder="Masjid/Organization name"
                        
                    />

                    <TextField 
                        id="outlined-basic" 
                        className={classes.inputStyle} 
                        variant="outlined" 
                        placeholder="Your email address"
                        helperText="Email must be formatted correctly"
                    />

                    
                    <Button variant="contained" className="ButtonWhiteFilled">Continue <img src={GreenArrow} /></Button> 
                </div>
            </Container>

        </div>
    );
}


export default BookingForm;