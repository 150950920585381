import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MasjidImg from '../assets/imgs/MasjidImg.svg'
import GreenArrow from '../assets/imgs/GreenArrow.svg'
import { Link as RouterLink } from 'react-router-dom';
import BannerCarousel from './BannerCarousel'






function Banner() {

    return (

        <div className="GreenBG MainSection">
            
            <Container maxWidth="lg" className='HomebannerContainer'>
                <Typography variant="h1" className="TitleLarge bannerTitile WhiteText AlignCenter">
                    Build a user friendly and modern <br/>website within 2 weeks for your
                     <br />
                     <BannerCarousel />
                </Typography>

                <div className="GetStartForm">
                    <TextField id="outlined-basic" className="GetStartedField"  variant="outlined" placeholder="Your email address"/>
                    <Button component={RouterLink} variant="contained" className="GetStartedButton" to='/get-quote'>GET STARTED <img src={GreenArrow} /></Button> 
                </div>
            </Container>

            <div className="masjidImg"><img src={MasjidImg} /></div>
        </div>
    );
}


export default Banner;