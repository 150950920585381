import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import WhiteArrow from '../assets/imgs/whiteArrow.svg'
import MasjidImg from '../assets/imgs/MasjidImg.svg'
import website_build_banner from '../assets/imgs/website_build_banner.png'
import CheckCircle from '../assets/imgs/checkCircle.svg'
import { Link as RouterLink } from 'react-router-dom';







function WhatSpecial() {

    return (

        <div className="GreyBG MainSection">

            <div className="FullWidthGridContainer">
                <div className="websiteImg"><img src={website_build_banner} /></div>

                <div className="WebsiteContents">
                    <Typography variant="h1" className="TitleGreen GreenText">
                        What's special about our websites?
                    </Typography>
                    <Typography variant="h1" className="TitleSmall DarkteText">
                        Websites built to connect communities and Masajid
                    </Typography>

                    <div className="SrList">
                        <li><img src={CheckCircle} /> Modern and beautiful designs</li>
                        <li><img src={CheckCircle} /> Optimized for desktop and mobile</li>
                        <li><img src={CheckCircle} /> Safe and secured with top encryption</li>
                        <li><img src={CheckCircle} /> Most affordable and free integrations</li>
                        <li><img src={CheckCircle} /> Ongoing support and consultation</li>
                        
                    </div>

                    <Button variant="contained" className="ButtonGreenFilled" component={RouterLink} to='/get-quote'>Let's GET STARTED <img src={WhiteArrow} /></Button> 
                    
                </div> 
           </div>
           

            
        </div>
    );
}


export default WhatSpecial;