import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './components/Home'
import PricingPage from './components/PricingPage'
import GetAQuote from './components/GetAQuote'
import BlogsListing from './components/Blogs/BlogsListing'
import BlogDetails from './components/Blogs/BlogDetails'
import ContactUs from './components/ContactUs'
import Thanks from './components/Thanks'


function App() {
 

  return (
      <Router>
        <Route exact path={"/"} component={() => <Home />} />
        <Route exact path={"/pricing"} component={() => <PricingPage />} />
        <Route exact path={"/get-quote"} component={() => <GetAQuote />} />
        <Route exact path={"/blog-listing"} component={() => <BlogsListing />} />
        <Route exact path={"/blog-details"} component={() => <BlogDetails />} />
        <Route exact path={"/contact-us"} component={() => <ContactUs />} />
        <Route exact path={"/thanks"} component={() => <Thanks />} />
      </Router>
  );

}

export default App;
