import React, { useEffect } from "react";
import { TabDrawer } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import Header from '../common/Header'
import Footer from '../common/Footer'
import Link from '@material-ui/core/Link';
import blogImg from '../../assets/imgs/Blog.jpg'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Subscription from '../Subscription/Subscription';

import './style.css'

function BlogsListing(props) {


	const RecentBlogCardData = [

		{
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "Flutter is no longer a cross-platform framework — it is something more.",
			path:"/blog-details",
		},

		{
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"/blog-details",
		},

		{
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "15 Amazing Travel App Designs You Should See",
			path:"/blog-details",
		},


		{
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"/blog-details",
		},


	]


	const BlogCardData = [

		{
			img: blogImg,
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"/blog-details",
		},

		{
			img: blogImg,
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"/blog-details",
		},

		{
			img: blogImg,
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"/blog-details",
		},

		{
			img: blogImg,
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"/blog-details",
		},

		{
			img: blogImg,
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"/blog-details",
		},

		{
			img: blogImg,
			update: "PRODUCT UPDATES",
			time: "5 MIN",
			title: "This is how Netflix, Snapchat, and Microsoft break UX Design principles",
			path:"/blog-details",
		},


	]




	const saveChanges = (accordianNum, accordianData) => {
		console.log("data", accordianNum, accordianData)
	}


	const TabDrawerStyle = {
		minHeight: "auto",
		top: "0px",
		position: "relative",
		border: "none"
	};

	const BodyStyle ={
		padding:"0px",
	  };



	return (
		<div>
			<Header />

			<div className="BlogListingMainContainer">

				<Grid container spacing={3}>

					<Grid item md={8} sm={8} xs={12}>
						<div className="BlogListingCardLarge">
							<div className="BlogListingCardLargeImg"><img src={blogImg} /></div>

							<div className="BlogListingCardLargeInfo">
								<div className="BlogListingCardLargeInfoPost">
									PRODUCT UPDATES <span></span> 5 MIN READ
								</div>
								<h1>Flutter is no longer a cross-platform framework — it is something more.</h1>

								<a className="BlogReadMoreButton" href={process.env.REACT_APP_Environment === 'dev' ? "https://shapla.admin.deenconnect.io/signup" : "https://shapla.admin.deenconnect.io/signup"}>
									READ NOW <ArrowForwardIosRoundedIcon />
								</a>

							</div>
						</div>
					</Grid>


					<Grid item md={4} sm={4} xs={12}>
						<div className="TabMainContainer">
							<TabDrawer

								labelOne="RECENT"
								labelTwo="PRODUCT UPDATES"
								TabDrawerStyle={TabDrawerStyle}
								BodyStyle={BodyStyle}
								TabOneContent={

									<div>

										{RecentBlogCardData.map((item, i) => (
										<div className="BlogListingRecentCardSmall">	
										<a href={item.path}>
										<div className="BlogListingCardSmallInfo">
											<div className="BlogListingCardSmallInfoPost">
												{item.update} <span></span> {item.time} READ
											</div>
											<h1>{item.title}</h1>
										</div>
										</a>
										</div>
										))}

									</div>

								}

								TabTwoContent={

									<div>

										{RecentBlogCardData.map((item, i) => (
										<div className="BlogListingRecentCardSmall">	
										<a href={item.path}>
										<div className="BlogListingCardSmallInfo">
											<div className="BlogListingCardSmallInfoPost">
												{item.update} <span></span> {item.time} READ
											</div>
											<h1>{item.title}</h1>
										</div>
										</a>
										</div>
										))}

									</div>

								}

							/>
						</div>
					</Grid>

					<Grid item md={12} sm={12} xs={12}>
						<Subscription />
					</Grid>

				</Grid>

				<div className="BlogListingSection">
					<Grid container spacing={3}>

						{BlogCardData.map((item, i) => (
							<Grid item md={4} sm={6} xs={12}>
								<a href={item.path}>
									<div className="BlogListingCardSmall">
										<div className="BlogListingCardSmallImg"><img src={item.img} /></div>

										<div className="BlogListingCardSmallInfo">
											<div className="BlogListingCardSmallInfoPost">
												{item.update} <span></span> {item.time} READ
											</div>
											<h1>{item.title}</h1>

											<span className="BlogSmallCardReadMoreButton" href={process.env.REACT_APP_Environment === 'dev' ? "https://shapla.admin.deenconnect.io/signup" : "https://shapla.admin.deenconnect.io/signup"}>
												READ NOW <ArrowForwardIosRoundedIcon />
											</span>

										</div>
									</div>
								</a>
							</Grid>
						))}


						<Grid item md={12} sm={12} xs={12}>
							<div className="LoadmoreButtonContainer">LOAD MORE POSTS</div>
						</Grid>


					</Grid>
				</div>


			</div>

			<Footer />
		</div>
	);
}

export default BlogsListing;
