import React, { Component } from 'react';
import Banner from './Banner'
import Header from './common/Header'
import Footer from './common/Footer'
import PricingTable from './PricingTable'

import '../assets/css/style.css'


function PricingPage(props) {

    return (

        <div className="PricingTableContainers">
            <Header />
                <PricingTable />
            <Footer />
        </div>
    );
}


export default PricingPage;