import React, { createRef, useState, useEffect } from 'react'
import Header from './common/Header'
import { PhoneInputField, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Footer from './common/Footer'
import { DropdownMultiSelect, SingleDrawerDateandTime, TextInputFixedLabel } from "@dclab/dc-react-ui-kit";

import { TextEditor } from "@dclab/dc-react-ui-kit";
import '../assets/css/style.css'
import axios from "axios";
import { AlertAction } from "@dclab/dc-react-ui-kit";

import Thanks from './Thanks';

import TextField from '@material-ui/core/TextField';


function GetAQuote(props) {

    const getQouteRef = createRef();

    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.indexOf("/get-quote") > -1) {
                // getQouteRef && getQouteRef.current.scrollIntoView({ behavior: "smooth" });

            }
        }, 1000);

    }, [getQouteRef]);


    const [submitButtonClass, setSubmitButtonClass] = React.useState('ButtonGreenFilled Mui-disabled');


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });

    const [dateTime, setDateTime] = React.useState();
    const getDateTime = (value) => {
        // console.log(value.format('MMM dd YYYY'))
        setValues({ ...values, ['projectStartDate']: value });
    }


    const [values, setValues] = React.useState({
        name: "hello",
        firstName: '',
        lastName: '',
        email: '',
        interestedIn: '',
        organizationName: '',
        stream: 'Website For Masjid', 
        projectStartDate: '' ,
    });

    const handleChange = (prop) => (event) => {
        if( prop == 'email'){
            const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;
            if (emailRegex.test(event.target.value)) {
                setEmailClass(false);
            } else {
                setEmailClass(true);
            }
        }
        
        setValues({ ...values, [prop]: event.target.value });
    };

    const viewChange = (newValue) => {
        console.log("Coming from View Change: ", newValue[0].title );
        setValues({ ...values, ['interestedIn']: newValue[0].title });
    };

    const Listdata = [
        {
            contactId: "6165ac907043bb0008c942d3",
            groupContacts: [],
            title: "New website development"
        },
        {
            contactId: "615aec4ea1dd53000874cda0",
            title: "Redesign existing website ",
        },
        {
            contactId: "6170389b9da5450009e719ad",
            title: "Develop existing website",
        },
        {
            contactId: "61436db18c6dde0009b2091f",
            title: "Website hosting",
        },

    ];



    const selectedValues = [
        // { title: 'The Shawshank Redemption', year: 1994 },
        // { title: 'The Godfather', year: 1972 },
        // { title: 'The Godfather: Part II', year: 1974 },
    ];

    const group = {
        'The Shawshank Redemption': 1994,
        'The Godfather': 1972,
        'Shaft\'s Big Score!': 1972,
        'The Godfather: Part II': 1974,
    }

    const groupByCallBack = (option) => {
        return group[option.title]
    }

    const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: "" }] }]);

    const onChange = (e) => {
        setValue(e);
        if (e[0].children[0].text && values.firstName && values.lastName && values.email && values.organizationName && phone.number) {
            setSubmitButtonClass('ButtonGreenFilled');
        } else {
            setSubmitButtonClass('ButtonGreenFilled Mui-disabled');
        }
    };

    const submitQuotebutton = (e) => {
        

        const orgBaseUrl = process.env.REACT_APP_BASEURL_API ; 

        const postData = {
            email: values.email,
            orgName: values.organizationName,
            message: value[0].children[0].text,
            stream: values.stream,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: '+' + phone.number, 
            interestedIn: values.interestedIn, 
            projectStartDate: values.projectStartDate ,
        };

        // console.log(postData); return ; 
        return new Promise((resolve, reject) => {
            axios.post(`${orgBaseUrl}/quotations/createQuotation`, postData, {
                headers: {
                },
            })
                .then(res => {
                    setshowAlert(true);;
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                })
        });
    };


    const click = () => {
        setshowAlert(false);
    };

    const [showAlert, setshowAlert] = React.useState(false);

    const [firstNameClass, setFirstNameClass] = React.useState(''); //outlined-error-helper-text

    const [lastNameClass, setLastNameClass] = React.useState('');

    const [emailClass, setEmailClass] = React.useState('');

    const [orgClassName, setorgClassName] = React.useState('');

    const [messageClassName, setMessageClassName] = React.useState('textEditorContainer');

    return (

        <div>
            {showAlert && <Thanks />}
            {!showAlert &&
                <div>
                    <Header />

                    <div className="PageContainer BGColorGrey" ref={getQouteRef}>

                        <div className="PageTitlepattren">

                        </div>

                        <div className="GetQuoteFormContainer">
                            <h1>Get a quote</h1>

                            <div className="GetQuoteForm">
                                <div className='GetQuoteFormTopText'>We are on a mission! Tell us about your project. <br />We will be in touch shortly inshaAllah.</div>
                                <Grid container spacing={3}>

                                    <Grid item md={12} item sm={12} item xs={12}>

                                        <Grid container spacing={3}>
                                            <Grid item md={6} item sm={6} item xs={12}>

                                                <label class="input_label">First name <span>*</span></label>
                                                <TextField
                                                    placeholder="First name"
                                                    onChange={handleChange("firstName")}
                                                    error={firstNameClass}
                                                    id="outlined-error-helper-text"
                                                    variant="outlined"

                                                />
                                            </Grid>

                                            <Grid item md={6} item sm={6} item xs={12}>
                                                <label class="input_label">Last name <span>*</span></label>
                                                <TextField
                                                    placeholder="Last name"
                                                    onChange={handleChange("lastName")}
                                                    error={lastNameClass}
                                                    id="outlined-error-helper-text"
                                                    variant="outlined"

                                                />
                                            </Grid>



                                            <Grid item md={12} item sm={12} item xs={12}>
                                                <PhoneInputField
                                                    phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                                    country={'us'}
                                                    value={phone.number}
                                                    countryCodeEditable={false}
                                                    onChange={(phone, country) => setPhone({ country: country.dialCode, number: phone })}
                                                    onBlur={() => console.log("onBlur")}
                                                    // onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                                                    variant="outlined"
                                                    // onlyCountries={["us", "ca", "gb", "my"]}
                                                    containerStyle={{}}
                                                    buttonStyle={{}}
                                                    inputStyle={{
                                                        marginLeft: "0px",
                                                        height: "40px",
                                                        width: "100%",
                                                        fontFamily: "Roboto-Regular",
                                                        fontSize: "16px",
                                                    }}
                                                    containerClass={{}}
                                                    errorText={"Phone number is required"}
                                                    error={false}
                                                    errorStyles={{ marginBottom: "5px" }}
                                                    autoFormat={true}
                                                    disableDropdown={false}
                                                    disabled={false}
                                                    disableCountryCode={false}
                                                    enableSearch={false}
                                                    disableSearchIcon={false}
                                                    placeholder={""}
                                                    inputProps={{}}
                                                    specialLabel={'Primary Phone'}
                                                    // preferredCountries={[]}
                                                    // excludeCountries={[]}
                                                    labelFixed="Phone number"
                                                    LabelStatus={true}
                                                />
                                            </Grid>

                                            <Grid item md={12} item sm={12} item xs={12}>
                                                <label class="input_label">Email address <span>*</span></label>
                                                <TextField
                                                    placeholder="Email address"
                                                    onChange={handleChange("email")}
                                                    error={emailClass}
                                                    id="outlined-error-helper-text"
                                                    variant="outlined"
                                                />
                                            </Grid>



                                            <Grid item md={12} item sm={12} item xs={12}>
                                            <label class="input_label">Masjid/Organization name <span>*</span></label>
                                                <TextField
                                                    placeholder="Masjid/Organization name"
                                                    onChange={handleChange("organizationName")}
                                                    error={orgClassName}
                                                    id="outlined-error-helper-text"
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid item md={12} item sm={12} item xs={12}>
                                                <div className='InterestedFieldContainer'>
                                                    <label class="input_label">Interested in</label>
                                                    <DropdownMultiSelect
                                                        label="Select"
                                                        Listdata={Listdata.sort((a, b) => a.year - b.year)}
                                                        variant="standard"
                                                        placeholder="Search contacts/groups"
                                                        // selected={selectedValues}
                                                        groupByCallBack={groupByCallBack}
                                                        //error="Field is required"
                                                        fieldToSelect='Description'
                                                        variant="outlined"
                                                        onChange={handleChange("interestedIn")}
                                                        viewChange={viewChange}
                                                        value={values.name}
                                                    />

                                                </div>
                                            </Grid>


                                            <Grid item md={12} item sm={12} item xs={12}>
                                                <div className='CustomField'>
                                                    <label class="input_label">Project start date</label>
                                                    <SingleDrawerDateandTime
                                                        title={""} isDateorTime={true}
                                                        timezone={''}
                                                        getDateTime={getDateTime}
                                                        placeholder="MM/DD/YYYY"
                                                    />
                                                </div>
                                            </Grid>


                                            <Grid item md={12} item sm={12} item xs={12}>
                                                <div className={messageClassName}>
                                                    <label class="input_label">Your message <span>*</span></label>
                                                    <TextEditor
                                                        onChange={(e) => onChange(e)}
                                                        disabled={false}
                                                        value={value}
                                                        //helperText={"Helper Text"}
                                                        maxCharCount={300}
                                                        placeholder="Enter your message here..."
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item md={12} item sm={12} item xs={12}>

                                                <div className="ButtonSectionInnerCol">
                                                    <Button className="cencelButton" variant="contained">Cancel</Button>
                                                    <Button onClick={(e) => submitQuotebutton(e)} variant="contained" className={submitButtonClass}>SUBMIT</Button>
                                                </div>
                                            </Grid>




                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>


                        </div>



                    </div>
                    <Footer />
                </div>
            }

        </div>

    );
}


export default GetAQuote;