import React, { createRef, useState, useEffect } from 'react'
import Banner from './Banner'
import Header from './common/Header'
import '../assets/css/style.css'

import WhatSpecial from './WhatSpecial'
import BookingForm from './BookingForm'
import EmbedAnything from './EmbedAnything'
import BuildProjectPlan from './BuildProjectPlan'
import WebsiteFeatures from './WebsiteFeatures'
import PricingTable from './PricingTable'
import WebsiteListing from './WebsiteListing'
import WhyUs from './WhyUs'
import Blogs from './Blogs'
import FollowUs from './FollowUs'
import SendInquiry from './SendInquiry'
import Footer from './common/Footer'


function Home() {
    const homeRef = createRef();
    const featureRef = createRef();
    const whyusRef = createRef();
    const integrationRef = createRef();
    const websiteRef = createRef();
    const getQouteRef = createRef();

    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.indexOf("/#home") > -1) {
                homeRef && homeRef.current.scrollIntoView({ behavior: "smooth" });

            } else if (window.location.href.indexOf("/#integration") > -1) {
                integrationRef && integrationRef.current.scrollIntoView({ behavior: "smooth" });

            } else if (window.location.href.indexOf("/#features") > -1) {
                featureRef && featureRef.current.scrollIntoView({ behavior: "smooth" });

            } else if (window.location.href.indexOf("/#why-us") > -1) {
                whyusRef && whyusRef.current.scrollIntoView({ behavior: "smooth" });

            } else if (window.location.href.indexOf("/#our-work") > -1) {
                websiteRef && websiteRef.current.scrollIntoView({ behavior: "smooth" });


            }
            else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth', });
            }
        }, 1000);

    }, [homeRef,featureRef, whyusRef, integrationRef, websiteRef, getQouteRef ]);




    return (

        <div  className='HomePageContainer'>
            <div ref={homeRef}>
            <Header />
            <div id="body">
            <Banner />
            </div>
            
            <WhatSpecial />
            <BookingForm />
            <EmbedAnything integrationRef={integrationRef}/>
            <BuildProjectPlan />
            <WebsiteFeatures featureRef={featureRef} />
            {/* <PricingTable /> */}
            <WebsiteListing websiteRef={websiteRef}/>
            <WhyUs  whyusRef={whyusRef} />
            {/* <Blogs /> */}
            <FollowUs />
            <SendInquiry />
            </div>
            <Footer />
        </div>
    );
}


export default Home;