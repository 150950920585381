
import React from 'react';
import facebookIcon from '../../assets/imgs/DEENCONNECT_FacebookBlue.png';
import { Email } from '@material-ui/icons';
import Container from '@material-ui/core/Container';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import './header.css'







function HeaderTopBar() {



    return (

        <div className="TopbarContainer">
            <Container maxWidth="lg" className="TopbarContainerInner">
                <div className='HeaderTopLeftCol'>
                    <a href="mailto:websiteformasjid@gmail.com"><Email  /> <span>Website</span>For<span>Masjid</span>@gmail.com</a>
                    <a href="tel: +1 (646) 494-3540"><CallRoundedIcon /> +1 (646) 494-3540</a>
                </div>

                <div className='HeaderTopRightCol'>
                    <a href='https://www.facebook.com/DeenConnect-100626982170606' target='blank' > <img style={{ width: "20px" }} src={facebookIcon} /></a>
                    {/* <a href="#" className={classes.socialIcon}> <img src={twitterIcon} /></a>
                    <a href="#" className={classes.socialIcon}> <img src={whatsappIcon} /></a> */}
                </div>
            </Container>

        </div>
    );
}


export default HeaderTopBar;









