import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import GreenArrow from '../assets/imgs/GreenArrow.svg'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { InputButton } from "master-react-lib";
import buildMasjid from '../assets/imgs/buildMasjid.jpg'










function Blogs() {

    return (

        <div className="GreyBG MainSection">


            <Container maxWidth="lg">
                <Typography variant="h1" className="TitleLarge DarkteText AlignCenter bottomSpace">
                    Some great tips
                </Typography>


                <Grid container spacing={3}>

                    <Grid item md={3} sm={6} xs={12}>
                        <Link className="Card articleCard">
                                <div className="artcleImg"><img src={buildMasjid} /></div>
                                <div className="articleCardInfo">
                                    <h2>Product updates . 5 min read</h2>
                                    <h1>Using HTTPS for Local Development for React, Angular, and Node</h1>
                                    <Button href="#text-buttons">Read now <ArrowForwardIosRoundedIcon /></Button>
                                </div>
                        </Link>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <Link className="Card articleCard">
                                <div className="artcleImg"><img src={buildMasjid} /></div>
                                <div className="articleCardInfo">
                                    <h2>Product updates . 5 min read</h2>
                                    <h1>Using HTTPS for Local Development for React, Angular, and Node</h1>
                                    <Button href="#text-buttons">Read now <ArrowForwardIosRoundedIcon /></Button>
                                </div>
                        </Link>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <Link className="Card articleCard">
                                <div className="artcleImg"><img src={buildMasjid} /></div>
                                <div className="articleCardInfo">
                                    <h2>Product updates . 5 min read</h2>
                                    <h1>Using HTTPS for Local Development for React, Angular, and Node</h1>
                                    <Button href="#text-buttons">Read now <ArrowForwardIosRoundedIcon /></Button>
                                </div>
                        </Link>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <Link className="Card articleCard">
                                <div className="artcleImg"><img src={buildMasjid} /></div>
                                <div className="articleCardInfo">
                                    <h2>Product updates . 5 min read</h2>
                                    <h1>Using HTTPS for Local Development for React, Angular, and Node</h1>
                                    <Button href="#text-buttons">Read now <ArrowForwardIosRoundedIcon /></Button>
                                </div>
                        </Link>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <div style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
                        <InputButton
                            label="SEE ALL BLOGS"
                            //link=""
                            //target="blank"
                            path="/blog-listing"

                            arrow={false}
                            arrowColor="#fff"

                            addIcon={false}
                            AddIconColor="#0053A5"

                            buttoncolor="#2E4BF2"
                            width="204px"
                            height="50px"
                            border="#ccc 0px solid"
                            borderRadius="4px"
                            color="#fff"
                            fontWeight="600"
                            textAlign="center"
                        />
                        </div>
                    </Grid>

                    

                </Grid>

            </Container>



        </div>
    );
}


export default Blogs;