import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '../assets/imgs/socialicons/FacebookRounded.svg';
import FacebookIconWhite from '../assets/imgs/DEENCONNECT_Facebook.png';





function FollowUs() {

    return (

        <div className="GreyBG MainSection">


            <Container maxWidth="lg">
                <div className="blogSectioTitle  AlignCenter bottomSpace">
                Follow us on Facebook to stay updated<br/> about our news and latest updates
                </div>


                <Grid container spacing={3}>

                    <Grid item md={12} sm={12} xs={12}>
                    <div className="SocialIconContainer">
                      <a href='https://www.facebook.com/DeenConnect-100626982170606' target='blank'><img src={FacebookIconWhite} /> FOLLOW US ON FACEBOOK</a>
                      </div>
                    </Grid>


                </Grid>

            </Container>



        </div>
    );
}


export default FollowUs;