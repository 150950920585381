import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme, createStyles, makeStyles, } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch'
import GreenArrowDown from '../assets/imgs/GreenArrowDown.svg'

import WhiteArrow from '../assets/imgs/whiteArrow.svg'
import Badge from '@material-ui/core/Badge';
import Arrowforward from '../assets/imgs/arrowforward.svg'









const StyledTableCell = withStyles((theme) =>
    createStyles({

    }),
)(TableCell);

const StyledTableRow = withStyles((theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);




function PricingTable(props) {


    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };








    const [] = React.useState(null);
    const [show_1, pric_1] = useState(true)
    const [show_2, pric_2] = useState(false)
    const [show_3, pric_3] = useState(false)




    const [toggleDevelopmentState, setToggleDevelopmentState] = useState(0);

    const DevelopmentTab = (index) => {
        setToggleDevelopmentState(index);
    };


    const [toggleWebsiteState, setToggleWebsiteState] = useState(0);

    const WebsiteTab = (index) => {
        setToggleWebsiteState(index);
    };


    const [toggleBrandingState, setToggleBrandingState] = useState(0);

    const BrandingTab = (index) => {
        setToggleBrandingState(index);
    };

    return (

        <div className="GreyBG TabbleSectionpage">

            <Container maxWidth="lg" className="HideHome">
                <div className="BackButtonContainer"><Button href="#text-buttons"><img src={Arrowforward} /> Back</Button></div>
            </Container>

            <Container maxWidth="lg">



                <Typography variant="h1" className="TitleSmall DarkteText AlignCenter LeftText">
                    Simple pricing that works for all Masajid
                </Typography>

                <Typography variant="h1" className="SubTitle DarkteText AlignCenter LeftText">
                    Find a plan that's right for you. Or simply <Link to="/">contact our consultation team.</Link>
                </Typography>


                <div className="PricingTablemainContainer">
                    <TableContainer component={Paper}>
                        <Table>


                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className="PricingPackageTitleContainerTh">
                                        <div className="PricingPackageTitleContainer">
                                            <h3>Website Development</h3>
                                            <div className="PriceSwitch">
                                                <div className="SwitchStatus">One time cost</div>
                                            </div>

                                            <div className="ShowAllTableData">
                                                <Button href="#text-buttons" onClick={() => pric_1(!show_1)}>Show all features <img className="buttonArrow" src={GreenArrowDown} /></Button>
                                            </div>

                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleDevelopmentState === 1 ? "PricingCell Active" : "PricingCell"}>
                                        <div className="pricingTitle">
                                            <h1 className="prTitleName">Starter</h1>
                                            <div className="pricingTitleBottomBody">
                                                <h2>$585</h2>
                                                <p>Starting price $585 +</p>
                                                <p className="packageD">A flexible voice solution that’s easy to set up, integrate and manage</p>
                                                <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => DevelopmentTab(1)}>SELECT <CheckCircleOutlineRoundedIcon /> </Button>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleDevelopmentState === 2 ? "PricingCell Active" : "PricingCell"}>
                                        <div className="pricingTitle">
                                            <h1 className="prTitleName">PREMIUM</h1>
                                            <div className="pricingTitleBottomBody">
                                                <h2>$1275</h2>
                                                <p>Final amount after meeting</p>
                                                <p className="packageD">Advanced solution to help boost productivity and performance</p>
                                                <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => DevelopmentTab(2)}>SELECT <CheckCircleOutlineRoundedIcon /></Button>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleDevelopmentState === 3 ? "PricingCell Active" : "PricingCell"}>
                                        <div className="pricingTitle">
                                            <h1 className="prTitleName">CUSTOM</h1>
                                            <div className="pricingTitleBottomBody">
                                                <h3>Let's talk</h3>
                                                <p></p>
                                                <p className="packageD">Customized package aligned with your business needs</p>
                                                <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => DevelopmentTab(3)}>SELECT <CheckCircleOutlineRoundedIcon /></Button>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                </TableRow>
                            </TableHead>



                            {show_1 ? <TableBody>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>


                            </TableBody> : null}


                        </Table>
                    </TableContainer>


                </div>





                <div className="PricingTablemainContainer">
                    <TableContainer component={Paper}>
                        <Table>


                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className="PricingPackageTitleContainerTh">
                                        <div className="PricingPackageTitleContainer">
                                            <h3>Website Hosting</h3>
                                            <div className="PriceSwitch">
                                                <div className="SwitchStatus">Bill Monthly</div>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={state.checkedB}
                                                            onChange={handleChange}
                                                            name="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                                <div className="SwitchStatus withSave">Bill Anually <span>(Save 20%)</span></div>
                                            </div>

                                            <div className="ShowAllTableData">
                                                <Button href="#text-buttons" onClick={() => pric_2(!show_2)}>Show all features <img className="buttonArrow" src={GreenArrowDown} /></Button>
                                            </div>

                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleWebsiteState === 1 ? "PricingCell Active" : "PricingCell"}>
                                        <div >
                                            <div className="pricingTitle">
                                                <h1 className="prTitleName">Starter</h1>
                                                <div className="pricingTitleBottomBody">
                                                    <h2>$19</h2>
                                                    <p>/month, paid yearly</p>
                                                    <p className="packageD">A flexible voice solution that’s easy to set up, integrate and manage</p>
                                                    <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => WebsiteTab(1)}>SELECT <CheckCircleOutlineRoundedIcon /></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleWebsiteState === 2 ? "PricingCell Active" : "PricingCell"}>
                                        <div className="pricingTitle">
                                            <h1 className="prTitleName">Plus</h1>
                                            <div className="pricingTitleBottomBody">
                                                <h2>$25</h2>
                                                <p>/month, paid yearly</p>
                                                <p className="packageD">Advanced solution to help boost productivity and performance</p>
                                                <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => WebsiteTab(2)}>SELECT <CheckCircleOutlineRoundedIcon /></Button>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleWebsiteState === 3 ? "PricingCell Active" : "PricingCell"}>
                                        <div className="pricingTitle">
                                            <h1 className="prTitleName">Premium</h1>
                                            <div className="pricingTitleBottomBody">
                                                <h2>$30</h2>
                                                <p>/month, paid yearly</p>
                                                <p className="packageD">Customized package aligned with your business needs</p>
                                                <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => WebsiteTab(3)}>SELECT <CheckCircleOutlineRoundedIcon /></Button>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                </TableRow>
                            </TableHead>



                            {show_2 ? <TableBody>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>


                            </TableBody> : null}


                        </Table>
                    </TableContainer>


                </div>










                <div className="PricingTablemainContainer">
                    <TableContainer component={Paper}>
                        <Table>


                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className="PricingPackageTitleContainerTh">
                                        <div className="PricingPackageTitleContainer">
                                            <h3>Branding Package</h3>
                                            <div className="PriceSwitch">
                                                <div className="SwitchStatus">One time cost</div>
                                            </div>

                                            <div className="ShowAllTableData">
                                                <Button href="#text-buttons" onClick={() => pric_3(!show_3)}>Show all features <img className="buttonArrow" src={GreenArrowDown} /></Button>
                                            </div>

                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleBrandingState === 1 ? "PricingCell Active" : "PricingCell"}>
                                        <div className="pricingTitle">
                                            <h1 className="prTitleName">Starter</h1>
                                            <div className="pricingTitleBottomBody">
                                                <h2>$245</h2>
                                                <p></p>
                                                <p className="packageD">A flexible voice solution that’s easy to set up, integrate and manage</p>
                                                <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => BrandingTab(1)}>SELECT <CheckCircleOutlineRoundedIcon /></Button>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleBrandingState === 2 ? "PricingCell Active" : "PricingCell"}>
                                        <div className="pricingTitle">
                                            <h1 className="prTitleName">Premium</h1>
                                            <div className="pricingTitleBottomBody">
                                                <h2>$375</h2>
                                                <p></p>
                                                <p className="packageD">Advanced solution to help boost productivity and performance</p>
                                                <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => BrandingTab(2)}>SELECT <CheckCircleOutlineRoundedIcon /></Button>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                    <StyledTableCell className={toggleBrandingState === 3 ? "PricingCell Active" : "PricingCell"}>
                                        <div className="pricingTitle">
                                            <h1 className="prTitleName">Custom</h1>
                                            <div className="pricingTitleBottomBody">
                                                <h3>Let's talk</h3>
                                                <p></p>
                                                <p className="packageD">Customized package aligned with your business needs</p>
                                                <Button className="priceSelectButton" variant="outlined" color="secondary" onClick={() => BrandingTab(3)}>SELECT <CheckCircleOutlineRoundedIcon /></Button>
                                            </div>
                                        </div>
                                    </StyledTableCell>

                                </TableRow>
                            </TableHead>



                            {show_3 ? <TableBody>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>

                                <StyledTableRow>

                                    <StyledTableCell align="left">
                                        <div className="PricingListTitle">
                                            Free .com or .org Domain
                                            <Tooltip className="tooltipStyle" title="Customize your own donation form to match organization brand and embed in your website." arrow><InfoOutlinedIcon className="PrcInfoIcon" /></Tooltip>
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >

                                    <StyledTableCell align="center">
                                        <div className="PricingListStatus">
                                            <CheckCircleOutlineRoundedIcon className="tableCheckIcon" />
                                        </div>
                                    </StyledTableCell >


                                </StyledTableRow>


                            </TableBody> : null}


                        </Table>
                    </TableContainer>


                </div>

                <div className="AlignCenter">
                    <Badge badgeContent={3} color="secondary">
                        <Button variant="contained" className="ButtonGreenFilled">Get a quote</Button>
                    </Badge>
                </div>

            </Container>



        </div>
    );
}


export default PricingTable;