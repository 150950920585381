import React, { createRef, useState, useEffect } from 'react'
import Header from './common/Header'
import { PhoneInputField, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Footer from './common/Footer'
import { TextEditor, DropDown } from "@dclab/dc-react-ui-kit";
import axios from "axios";
import { AlertAction } from "@dclab/dc-react-ui-kit";
import '../assets/css/style.css';
import TextField from '@material-ui/core/TextField';

const SITE_KEY = "6LfSwPUdAAAAAC1Q3pNsSuigwh19o7b4nL-8wOER";


function ContactUs(props) {

    const [firstNameClass, setFirstNameClass] = React.useState(''); //outlined-error-helper-text

    const [lastNameClass, setLastNameClass] = React.useState('');

    const [emailClass, setEmailClass] = React.useState('');

    const [messageClass, setMessageClass] = React.useState('textEditorContainer');

    // const contactRef = createRef();


    // useEffect(() => {
    //     setTimeout(() => {
    //         if (window.location.href.indexOf("/contact-us") > -1) {
    //             contactRef && contactRef.current.scrollIntoView({ behavior: "smooth" });

    //         }
    //     }, 1000);

    // }, [contactRef]);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
       
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
       
          if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          console.log("Script loaded!");
        });
      }, []);


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });

    const [submitButtonClass, setSubmitButtonClass] = React.useState('ButtonGreenFilled Mui-disabled');

    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };



    const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: "" }] }]);

    const onChange = (e) => {
        setValue(e);

        if (e[0].children[0].text && values.firstName && values.lastName && values.email && values.inquiryType) {
            setSubmitButtonClass('ButtonGreenFilled');
        } else {
            
            setSubmitButtonClass('ButtonGreenFilled Mui-disabled');
        }
    };




    const [values, setValues] = React.useState({
        age: "",
        firstName: '',
        lastName: '',
        email: '',
        inquiryType: "Select",
        stream: 'WEBSITE_FOR_MASJID',
    });

    const handleChange = (prop) => (event) => {
        if( prop == 'email'){

            const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;
            if (emailRegex.test(event.target.value)) {
                setEmailClass(false);
            } else {
                setEmailClass(true);
            }

            setValues({ ...values, [prop]: event.target.value });

        }else{
            setValues({ ...values, [prop]: event.target.value });
        }
        

        // console.log("Value")
        // console.log(event.target.value)


    };

    const [showAlert, setshowAlert] = React.useState(false);


    const submitContactus = (e) => {

        e.preventDefault();

        const orgBaseUrl = process.env.REACT_APP_BASEURL_API;  //'https://zaitoon.deenconnect.io';

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                const postData = {
                    email: values.email,
                    inquiryType: values.inquiryType,
                    message: value[0].children[0].text,
                    stream: values.stream,
                    name: {
                        firstName: values.firstName,
                        lastName: values.lastName
                    },
                    phone: '+' + phone.number,
                    "g-recaptcha-response" : token
                };

                // console.log(postData); return ; 
                return new Promise((resolve, reject) => {
                    axios.post(`${orgBaseUrl}/dc-contact-us/`, postData, {
                        headers: {
                        },
                    })
                        .then(res => {
                            setshowAlert(true);
                            resolve(res);

                            
                            setValues( {
                                age: "",
                                firstName: '',
                                lastName: '',
                                email: '',
                                inquiryType: '',
                                stream: 'WEBSITE_FOR_MASJID',
                            } );

                            

                            setValue( [{ type: 'paragraph', children: [{ text: "" }] }] ); 

                            setPhone({ country: '+1', number: '' }) ;

                        })
                        .catch(err => {
                            reject(err);
                        })
                });

            });
        });


        

        
    };



    const click = () => {
        setshowAlert(false);
    };



    const selectdata = [
        { value: "Select", select: "Select" },
        { value: "General Question", select: "General Question" },
        { value: "Website Design/Development", select: "Website Design/Development" },
        { value: "Internship Opportunities", select: "Internship Opportunities" },
        { value: "Volunteering", select: "Volunteering" },
        { value: "Partnership", select: "Partnership" },
        { value: "Suggestions", select: "Suggestions" },
        { value: "Media/Press", select: "Media/Press" },
        { value: "Subscription Form", select: "Subscription Form" },
    ];



    return (

        <div>
            <Header >

            </Header>

            <div className="PageContainer BGColorGrey" >


                <div className="PageTitlepattren">

                </div>


                <div className="GetQuoteFormContainer">
                    <h1>Contact us</h1>

                    {showAlert &&
                        <div className="EmailAlert">
                            <div className="EmailAlertContainer">
                                <AlertAction
                                    alert={"Message sent Successfully"}
                                    alertType={"success"}
                                    onClick={click}
                                />
                            </div>
                        </div>
                    }

                    <div className="GetQuoteForm">
                        <div className='GetQuoteFormTopText'>We’re very casual and would love to<br /> help you with your queries.</div>
                        <Grid container spacing={3}>

                            <Grid item md={12}  sm={12}  xs={12}>

                                <Grid container spacing={3}>

                                    <Grid item md={6}  sm={6}  xs={12}>
                                        <label class="input_label">First name <span>*</span></label>
                                        <TextField
                                            placeholder="First name"
                                            onChange={handleChange("firstName")}
                                            
                                            id={firstNameClass}
                                            variant="outlined"
                                            value={values.firstName}
                                        />
                                    </Grid>

                                    <Grid item md={6}  sm={6}  xs={12}>
                                        <label class="input_label">Last name <span>*</span></label>
                                        <TextField
                                            placeholder="Last name"
                                            onChange={handleChange("lastName")}
                                            error={lastNameClass}
                                            id="outlined-error-helper-text"
                                            variant="outlined"
                                            value={values.lastName}
                                        />
                                    </Grid>

                                    <Grid item md={12}  sm={12}  xs={12}>
                                        <label class="input_label">Email address <span>*</span></label>
                                        <TextField
                                            placeholder="Email address"
                                            onChange={handleChange("email")}
                                            error={emailClass}
                                            id="outlined-error-helper-text"
                                            variant="outlined"
                                            value={values.email}
                                        />
                                    </Grid>





                                    <Grid item md={12}  sm={12}  xs={12}>
                                        <PhoneInputField
                                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                            country={'us'}
                                            value={phone.number}
                                            countryCodeEditable={false}
                                            onChange={(phone, country) => setPhone({ country: country.dialCode, number: phone })}
                                            onBlur={() => console.log("onBlur")}
                                            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                                            variant="outlined"
                                            // onlyCountries={["us", "ca", "gb", "my"]}
                                            containerStyle={{}}
                                            buttonStyle={{}}
                                            inputStyle={{
                                                marginLeft: "0px",
                                                height: "40px",
                                                width: "100%",
                                                fontFamily: "Roboto-Regular",
                                                fontSize: "16px",
                                            }}
                                            containerClass={{}}
                                            errorText={"Phone number is required"}
                                            error={false}
                                            errorStyles={{ marginBottom: "5px" }}
                                            autoFormat={true}
                                            disableDropdown={false}
                                            disabled={false}
                                            disableCountryCode={false}
                                            enableSearch={false}
                                            disableSearchIcon={false}
                                            placeholder={""}
                                            inputProps={{}}
                                            specialLabel={'Primary Phone'}
                                            // preferredCountries={[]}
                                            // excludeCountries={[]}
                                            labelFixed="Phone number"
                                            LabelStatus={true}
                                        />
                                    </Grid>

                                    <Grid item md={12}  sm={12}  xs={12}>
                                        <div className='CustomField InquiryField'>
                                            <label class="input_label">Inquiry type *</label>

                                            <DropDown
                                                
                                                labelStatus={true}
                                                selectdata={selectdata}
                                                defaultValue={values.inquiryType}
                                                handleChange={handleChange("inquiryType")}
                                            />
                                        </div>
                                    </Grid>


                                    <Grid item md={12}  sm={12}  xs={12}>
                                        {/* <div className='textEditorContainer error'>  */}
                                        <div className={messageClass}>
                                            <label class="input_label">Your message <span>*</span></label>
                                            <TextEditor
                                                onChange={(e) => onChange(e)}
                                                disabled={false}
                                                value={value}
                                                //helperText={"Helper Text"}
                                                maxCharCount={300}
                                                placeholder="Enter your message here..."
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item md={12}  sm={12}  xs={12}>

                                        <div className="ButtonSectionInnerCol">
                                            <Button className="cencelButton" variant="contained">Cancel</Button>
                                            <Button onClick={(e) => submitContactus(e)} variant="contained" className={submitButtonClass}>SUBMIT</Button>
                                        </div>
                                    </Grid>




                                </Grid>
                            </Grid>
                        </Grid>
                    </div>


                </div>



            </div>
            <Footer />

        </div>
    );
}


export default ContactUs;