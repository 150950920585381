import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import GreenArrow from '../assets/imgs/launch_black_24dp.svg'
import { Link as RouterLink } from 'react-router-dom';

import web_1 from '../assets/imgs/websites/web_1.png'
import web_2 from '../assets/imgs/websites/web_2.png'
import web_3 from '../assets/imgs/websites/web_3.png'
import web_4 from '../assets/imgs/websites/web_4.png'
import web_5 from '../assets/imgs/websites/web_5.png'
import web_6 from '../assets/imgs/websites/web_6.png'

function WebsiteListing(props) {

    const click = () => {
        console.log("Back Button Clicked");
    };

    return (

        <div className="GreyBG MainSection WebsiteMainContainer" ref={props.websiteRef}>


            <Container maxWidth="lg">
                <Typography variant="h1" className="TitleLarge DarkteText AlignCenter singletitle">
                    Some of the websites developed by us
                </Typography>

                <Grid container spacing={3}>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card WebsiteBox" href="https://www.bicny.org/" target='_blank'>
                            <div className="websiteImgs"><img src={web_1} /></div>
                            <div className='WebsiteBoxInfoSection'>
                                <h1>Brooklyn Islamic Center</h1>
                                <Button >VIEW WEBSITE <img className="buttonArrow" src={GreenArrow} /></Button>
                            </div>
                        </Link>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card WebsiteBox" href="https://daruljannah.org/" target='_blank'>
                            <div className="websiteImgs"><img src={web_2} /></div>
                            <div className='WebsiteBoxInfoSection'>
                                <h1>Darul Jannah Masjid</h1>
                                <Button >VIEW WEBSITE <img className="buttonArrow" src={GreenArrow} /></Button>
                            </div>
                        </Link>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card WebsiteBox" href="https://alqurandc.org/" target='_blank'>
                            <div className="websiteImgs"><img src={web_3} /></div>
                            <div className='WebsiteBoxInfoSection'>
                                <h1>Al Quran Dawah Center</h1>
                                <Button target='blank'>VIEW WEBSITE <img className="buttonArrow" src={GreenArrow} /></Button>
                            </div>
                        </Link>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card WebsiteBox" href="https://build.bicny.org/" target='_blank'>
                            <div className="websiteImgs"><img src={web_4} /></div>
                            <div className='WebsiteBoxInfoSection'>
                                <h1>BIC - Build Project</h1>
                                <Button >VIEW WEBSITE <img className="buttonArrow" src={GreenArrow} /></Button>
                            </div>
                        </Link>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card WebsiteBox">
                            <div className="websiteImgs"><img src={web_5} /></div>
                            <div className='WebsiteBoxInfoSection'>
                                <h1>Helping Hand For Chittagonian</h1>
                                <div className='inprogressChip'>IN-PROGRESS</div>
                            </div>
                        </Link>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card WebsiteBox">
                            <div className="websiteImgs"><img src={web_6} /></div>
                            <div className='WebsiteBoxInfoSection'>
                                <h1>NAHAR Relief</h1>
                                <div className='inprogressChip'>IN-PROGRESS</div>
                            </div>
                        </Link>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Button variant="contained" className="ButtonGreenFilled" component={RouterLink} to="/get-quote">LET’S GET STARTED</Button>
                        </div>
                    </Grid>



                </Grid>

            </Container>



        </div>
    );
}


export default WebsiteListing;