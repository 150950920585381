import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import GreenArrow from '../assets/imgs/GreenArrow.svg'
import ScaleIcon from '../assets/imgs/ScaleIcon.png'
import Cardicon from '../assets/imgs/Cardicon.png'
import CloudIcon from '../assets/imgs/CloudIcon.png'
import { Link as RouterLink } from 'react-router-dom';


function BuildProjectPlan() {

    return (

        <div className="GreyBG MainSection">


            <Container maxWidth="lg">
                <Typography variant="h1" className="TitleLarge DarkteText AlignCenter">
                    What would you like to do?
                </Typography>

                <Typography variant="h1" className="SubTitle DarkteText AlignCenter">
                    Let’s get started building an amazing website for your Masjid.
                </Typography>



                <Grid container spacing={3}>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card BuildProjectBox" component={RouterLink} to='/get-quote'>
                            <div className="cardIcon"><img src={ScaleIcon} /></div>
                            <h1>New design & <br/>new website</h1>
                            <p className="Description">Work with our team to custom design and build a secured, user-friendly and SEO optimized, mobile responsive website for your organization or Masjid.</p>
                            <Button>Let's GET STARTED <img className="buttonArrow" src={GreenArrow} /></Button>
                        </Link>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card BuildProjectBox" component={RouterLink} to='/get-quote'>
                            <div className="cardIcon"><img src={Cardicon} /></div>
                            <h1>Redesign <br/>current website</h1>
                            <p className="Description">Work with our team to redesign your current website with latest trend, usability and compatibility across browsers and devices.</p>
                            <Button>Let's GET STARTED <img className="buttonArrow" src={GreenArrow} /></Button>
                        </Link>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                        <Link className="Card BuildProjectBox" component={RouterLink} to='/get-quote'>
                            <div className="cardIcon"><img src={CloudIcon} /></div>
                            <h1>Migrate <br/>Current website</h1>
                            <p className="Description">Migrate existing website so that you can get access to many free & paid integrations that are essential for your organizations growth & empowerment.</p>
                            <Button>Let's GET STARTED <img className="buttonArrow" src={GreenArrow} /></Button>
                        </Link>
                    </Grid>

                </Grid>

            </Container>



        </div>
    );
}


export default BuildProjectPlan;