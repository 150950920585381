import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import StarIcon from '../assets/imgs/StarIcon.png'
import TeamIcon from '../assets/imgs/TeamIcon.png'
import CheckIcon from '../assets/imgs/CheckIcon.svg'
import SpportCenter from '../assets/imgs/SpportCenter.png'
import GifteIcon from '../assets/imgs/GifteIcon.png'
import ChartIcon from '../assets/imgs/ChartIcon.png'






function WhyUs(props) {


    
    return (

        <div ref={props.whyusRef}>

            <div className="MainSection borderContainer">
            

                <div className="EmbedAnythingContents">
                    <Typography variant="h1" className="TitleGreen GreenText">
                        Why us?
                    </Typography>
                    <Typography variant="h1" className="TitleSmall DarkteText">
                         Because, we love what we do.
                    </Typography>



                    <div className="BottomContents">
                        <Grid container spacing={6}>

                            <Grid item md={4} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <div class="cardIcon"><img src={StarIcon} /></div>
                                    <h1>You are our priority.</h1>
                                    <Typography  className="Description">
                                    We take great pride of how we build our own products and we apply the same to build products for our Masajid too.
                                    </Typography>
                                </div>  
                            </Grid>

                            <Grid item md={4} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <div class="cardIcon"><img src={TeamIcon} /></div>
                                    <h1>We are proud of our team</h1>
                                    <Typography  className="Description">
                                        Our team members are experts with over decades of experience and well-versed with latest trends
                                    </Typography>
                                </div>  
                            </Grid>

                            <Grid item md={4} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <div class="cardIcon"><img src={CheckIcon} /></div>
                                    <h1>We are available</h1>
                                    <Typography  className="Description">
                                        Our team is available across three different countries and time zones. We usually reply within same day.    
                                    </Typography>
                                </div>  
                            </Grid>

                            <Grid item md={4} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <div class="cardIcon"><img src={SpportCenter} /></div>
                                    <h1>We are here. All the way.</h1>
                                    <Typography  className="Description">
                                        From the start our team will work with you till the website is launched successfully, and thereafter inshaAllah.
                                    </Typography>
                                </div>  
                            </Grid>

                            <Grid item md={4} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <div class="cardIcon"><img src={GifteIcon} /></div>
                                    <h1>Lot’s of free stuffs</h1>
                                    <Typography  className="Description">
                                         We have lot’s of free integrations so that your organization or Masjid can have better digital presence
                                    </Typography>
                                </div>  
                            </Grid>

                            <Grid item md={4} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <div class="cardIcon"><img src={ChartIcon} /></div>
                                    <h1>State-of-the-art technology</h1>
                                    <Typography  className="Description">
                                        We don’t build websites for 1999. We selected our technology to better secure and scale website on the Cloud.
                                    </Typography>
                                </div>  
                            </Grid>

                        </Grid>
                    </div>

                </div>
            
            </div>

        </div>
    );
}


export default WhyUs;