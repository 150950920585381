import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HandIcon from '../assets/imgs/handIcon.png'
import NikahIcon from '../assets/imgs/NikahIcon.png'
import MemberIcon from '../assets/imgs/MemberIcon.png'
import SalatIcon from '../assets/imgs/SalatIcon.png'
import EidianIcon from '../assets/imgs/EidianIcon.png'
import BuildProjectIcon from '../assets/imgs/BuildProjectIcon.png'

import BuildMasjid from '../assets/imgs/buildMasjid.jpg'






function WebsiteFeatures(props) {



    return (

        <div ref={props.featureRef}>

            <div className="MainSection borderContainer">


                <div className="EmbedAnythingContents">
                    <Typography variant="h1" className="TitleGreen GreenText">
                        Website features
                    </Typography>
                    <Typography variant="h1" className="TitleSmall DarkteText">
                        All the tools you need to run your Masjid and Islamic centers
                    </Typography>



                    <div className="BottomContents">
                        <Grid container spacing={3}>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Prayer/Iqamah Time</h1>
                                    <Typography className="Description">
                                        Manage Salah/Iqamah time for daily prayers, multiple Salat al-Jummah and Eidain (two Eid prayers)
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Events</h1>
                                    <Typography className="Description">
                                        Create/schedule one-time or recurring events from secured Masjid Admin Panel & publish anywhere
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Videos</h1>
                                    <Typography className="Description">
                                        Add videos to website by simply copying and pasting YouTube url/link in secured Masjid Admin Panel
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Services</h1>
                                    <Typography className="Description">
                                        Promote organization’s services and activities with pictures to website visitors
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>News/Articles</h1>
                                    <Typography className="Description">
                                        Publish insightful articles, news from organizations, and blogs on various topics and events
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Announcements</h1>
                                    <Typography className="Description">
                                        Announce important news and messages directly via your members and community
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Social Media Integration</h1>
                                    <Typography className="Description">
                                        Integrate Facebook, YouTube, Twitter and other social media url/link
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Photo Gallery</h1>
                                    <Typography className="Description">
                                        Display photos of organization’s services and activities to visually present amazing work done
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Free Domain</h1>
                                    <Typography className="Description">
                                        It’s on us, InshaAllah. We will buy a simple and rememberable domain for your organization or Masjid
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Free integrations from DEENCONNECT</h1>
                                    <Typography className="Description">
                                        Choose the integrations you need to effectively run your organizations
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Free SSL/Web security</h1>
                                    <Typography className="Description">
                                        We will add free SSL for 1st year with top encryption to keep the website secured
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                                <div className="FeatureList">
                                    <h1>Need Custom Solution?</h1>
                                    <Typography className="Description">
                                        We can custom build anything to your Masjid and organization needs
                                    </Typography>
                                </div>
                            </Grid>




                        </Grid>
                    </div>

                </div>

            </div>

        </div>
    );
}


export default WebsiteFeatures;