import React, { Component } from 'react';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Button from '@material-ui/core/Button';


function Thanks() {


    
    return (

        <div className="">

            <div className="MainSection">
            
                <div className="ThanksContainer">
                    <div className="checkIcon"><DoneOutlineIcon /></div>
                    <h1>Request submitted successfully</h1>
                    <p>We've received your request. One of our team member will respond to your request shortly</p>
                    <Button href="/#home" variant="contained" className="ButtonGreenFilled">Return to homepage</Button>
                </div>
                
            
            </div>

        </div>
    );
}


export default Thanks;