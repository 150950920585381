import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MasjidImg from '../assets/imgs/MasjidImg.svg'

import GreenArrow from '../assets/imgs/GreenArrow.svg'
import { Link as RouterLink } from 'react-router-dom';


function SendInquiry() {

    return (

        <div className="GreenBG MainSection">
            
            <Container maxWidth="lg">
                <Typography variant="h1" className="SubTitle  WhiteText AlignCenter">
                    We are on a mission to help Masajid and Not-for-profit organizations!
                </Typography>
                <Typography variant="h1" className="TitleSmall WhiteText AlignCenter">
                    Send us your queries. We love to help.
                </Typography>
                <div className="buttonCenter">
                    <Button variant="contained" className="ButtonWhiteFilled" component={RouterLink} to="/contact-us">Send inquiry <img src={GreenArrow} /></Button> 
                </div>
            </Container>
        </div>
    );
}


export default SendInquiry;