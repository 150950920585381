import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Typography from '@material-ui/core/Typography';

class BannerCarousel extends React.Component {
    render() {
        return (
            <Carousel autoPlay infiniteLoop axis="vertical" showArrows={false} showThumbs={false}>
                <div className="carousel-images">
                    <Typography variant="body1" className="m-salah">Masjid</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="d-donation">Islamic Centers</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="fundraise">Charity organization</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="announce">Humanitarian organization</Typography>
                </div>
                <div className="carousel-images">
                    <Typography variant="body1" className="kiosks">Not-for-profit organization</Typography>
                </div>
            </Carousel>
        )
    }
}
export default BannerCarousel;
