import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DcLogo from '../../assets/imgs/DC_Logo.png';
import Logo from '../../assets/imgs/DEENCONNECT_WebsiteForMasjid_WebsiteDevelopment_Icon.png';
import RecenNewstImg from '../../assets/imgs/articleImg.jpg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FacebookIcon from '../../assets/imgs/socialicons/FacebookRounded.svg';
import YouTubeRounded from '../../assets/imgs/socialicons/YouTubeRounded.svg';
import TwitterRounded from '../../assets/imgs/socialicons/TwitterRounded.svg';
import WhatsappRounded from '../../assets/imgs/socialicons/WhatsappRounded.svg';

import { TextInputFixedLabel, FooterWeb, } from "master-react-lib";
import Button from '@material-ui/core/Button';

import FacebookIconWhite from '../../assets/imgs/DEENCONNECT_FacebookBlue.png';







const useStyles = makeStyles((theme) => ({

    FooterMainBoxBottomGrey: {
        background: '#FBFAFD',
        padding: '15px',
        borderTop: '#ebeaed 1px solid',
    },

    FooterMainBoxBottomWhite: {
        background: '#fff',
        padding: '15px',
        borderBottom: '#7266FB 5px solid',
    },

    termText: {
        textAlign: 'left',
        fontSize: '12px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: '#828282',
        '@media (max-width: 960px)': {
            textAlign: 'center'
        }
    },

    termlink: { color: '#828282', },

    termTextRight: {
        textAlign: "right",

        '@media (max-width: 960px)': {
            textAlign: 'center'
        }

    },

    heartIcon: {
        color: '#828282',
        fontSize: '17px',
        verticalAlign: 'middle',
    },

    dclogoftr: {
        width: '146px',
        marginLeft: '5px',
    },
    pweredText: {
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        color: '#828282',
    },
    visitUs: {
        textAlign: "right",
        color: '#828282',
        textDecoration: 'none',
    },

    textislam: {
        color: '#828282',
    },

    facebookImg: {
        width: '26px',
        position: 'relative',
        top: '8px',
    },

    social_icon: {
        width: '41px',
        height: '41px',
        background: '#fff',
        border: '#666 1px solid',
        display: 'inline-block',
        borderRadius: '100%',
        margin: '0 8px',
    },

    facebook_icon: {
        width: '41px',
        height: '41px',
        display: 'inline-block',
        borderRadius: '100%',
        background: '#666',
        margin: '0 8px',
    },

    footerSocial: {
        paddingTop: '20px',
    },



    FooterCol: {

    },

    Footerlogo: {
        width: "65px",
    },

    FooterTitle: {
        fontSize: "16px",
        color: "#0B132B",
        fontWeight: "600",
        paddingBottom: "15px",
    },

    FooterLink: {
        fontSize: "16px",
        color: "#0B132B",
        fontWeight: "400",
        textDecoration: "none",
        marginBottom: "16px",
        display: "block",
        letterSpacing: 0,
    },

    RecentNewsImg: {
        width: '225px',
        height: '144px',

        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        }
    },

    UpdateTextr: {
        color: '#818E94',
        fontSize: '12px',
        textTransform: 'uppercase',
        margin: 0,
        marginTop: '-5px',

        '& svg': {
            fontSize: '10px',
        },
    },

    BottomText: {
        fontSize: "16px",
        color: "#0B132B",
        fontWeight: "600",
        textDecoration: "none",
        marginTop: '10px',
    },

    FooterLinkGreen: {
        fontSize: "16px",
        color: "#2E4BF2",
        fontWeight: "700",
        textTransform: 'uppercase',
        display: 'flex',
        textDecoration: 'none',

        '& svg': {
            marginLeft: "10px",
        }
    }

}));

function Footer() {
    const classes = useStyles();
    return (

        <div className='FooterMainContainer'>
            <Container maxWidth="lg" className="MainSection">
                <Grid container spacing={3}>

                    <Grid item md={6} sm={6} xs={12}>
                        <div style={{display:"flex", alignItems:"center"}}>
                        <div className={classes.FooterCol}>
                            <img className={classes.Footerlogo} src={Logo} />
                        </div>
                        <div className='FooterContactInfo'>
                            <p><a href="tel: +1 (646) 494-3540">+1 (646) 494-3540</a></p>
                            <p><a href="mailto:team@websiteformasjid.com">team@websiteformasjid.com</a></p>
                            {/* <p><a href="mailto:inquiry@websiteformasjid.com">inquiry@websiteformasjid.com</a></p> */}
                        </div>
                        </div>
                    </Grid>

                    <Grid item md={3} sm={3} xs={12}>
                        <div className={classes.FooterCol}>
                            <div className='FooterBottomLink'>
                                <a href='https://deenconnect.io/terms-policies#privacy-policy' target='blank'>Terms of Services</a>
                                <a href='https://deenconnect.io/terms-policies#privacy-policy' target='blank'>Privacy Policy</a>
                                <Link to='/contact-us'>Contact Us</Link>
                            </div>
                        </div>
                    </Grid>

                    <Grid item md={3} sm={3} xs={12}>
                        <div className={classes.FooterCol}>
                            <div className='FooterBottomLink FooterSocial'>
                            <a href='https://www.facebook.com/DeenConnect-100626982170606' target='blank'><img src={FacebookIconWhite} /> Follow us on Facebook</a>
                            </div>
                        </div>
                    </Grid>


                    {/* <Grid item md={4} sm={4} xs={12}>
                        <div className={classes.FooterCol}>
                            <h1 className={classes.FooterTitle}>Subscribe</h1>
                        </div>
                        <Grid container spacing={2}>

                            <Grid item md={12} item sm={12} item xs={12}>
                                <TextInputFixedLabel
                                    placeholder="Name"
                                    Errormessage="Error message"
                                    errortextStatus={false}
                                />
                            </Grid>

                            <Grid item md={12} item sm={12} item xs={12}>
                                <TextInputFixedLabel
                                    placeholder="Email address"
                                    Errormessage="Error message"
                                    errortextStatus={false}
                                />
                            </Grid>

                            <Grid item md={12} item sm={12} item xs={12}>
                                <Button variant="contained" className="ButtonGreenFilled widthFull">SUBSCRIBE</Button>
                            </Grid>

                        </Grid>
                    </Grid> */}



                </Grid>
            </Container>


            <div className='FooterBottomContainer'>
                <Container>
                    <div className='FooterBottom'>
                        <div className='FooterBottomLeft'>
                            <div variant="overline" display="block" className={classes.termText}>
                                © 2022 Website For Masjid. All Rights Reserved
                            </div>

                        </div>
                        <Typography variant="overline" display="block" className={classes.pweredText}>
                            POWERED BY <a href="https://deenconnect.io/" target='blank'><img className={classes.dclogoftr} src={DcLogo} alt="DC Logo" /></a>
                        </Typography>
                    </div>
                </Container>
            </div>

        </div>
    );
}


export default Footer;