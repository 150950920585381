import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HandIcon from '../assets/imgs/handIcon.png'
import NikahIcon from '../assets/imgs/NikahIcon.png'
import MemberIcon from '../assets/imgs/MemberIcon.png'
import SalatIcon from '../assets/imgs/SalatIcon.png'
import EidianIcon from '../assets/imgs/EidianIcon.png'

import janazaIcon from '../assets/imgs/janazaIcon.png'
import ramadanIcon from '../assets/imgs/ramadanIcon.png'

import BuildProjectIcon from '../assets/imgs/BuildProjectIcon.png'

import EmbedAnythingBanner from '../assets/imgs/Embed_anything.png'






function EmbedAnything(props) {

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (

        <div className="whiteBG MainSection" ref={props.integrationRef}>

            <Container maxWidth="lg">

                <div className="EmbedAnythingContents">
                    <Typography variant="h1" className="TitleGreen GreenText">
                        Embed anything
                    </Typography>
                    <Typography variant="h1" className="TitleSmall DarkteText">
                        Powerful integrations to help your Masjid connect and grow in the community.
                    </Typography>



                    <div className="BottomContents">
                        <Grid container spacing={3}>

                            <Grid item md={6} sm={6} xs={12}>

                                <div className="WbServicesList DonationBlog">

                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>

                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header" >
                                            <div>
                                                <Typography className="DonatoinFormTitle" ><img src={HandIcon} /> Donation Form</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Typography className="Description">
                                                Accept one-time and recurring donations from generous donors in secured & simple steps supported by DEENFUND.
                                            </Typography>
                                        </AccordionDetails>

                                    </Accordion>



                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>

                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <div>
                                                <Typography className="DonatoinFormTitle" ><img src={MemberIcon} />Membership Form</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Typography className="Description">
                                                Turn your visitors to life-time/paid members with simple online membership form & send fee reminders, communications, “thank you” notes etc
                                            </Typography>
                                        </AccordionDetails>

                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>

                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"  >
                                            <div>
                                                <Typography className="DonatoinFormTitle" ><img src={NikahIcon} /> Nikah Request Form</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Typography className="Description">
                                                Allow families to submit online form to host Walimah and Nikah ceremony/event
                                            </Typography>
                                        </AccordionDetails>

                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>

                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <div>
                                                <Typography className="DonatoinFormTitle" ><img src={janazaIcon} />Salat al-Janazah Request</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Typography variant="h1" className="Description">
                                                Allow families to report a death & request for Salat Al-Janaza /funeral prayer with details from basic online form
                                            </Typography>
                                        </AccordionDetails>

                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>

                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <div>
                                                <Typography className="DonatoinFormTitle" ><img src={ramadanIcon} />Ramadhan</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Typography variant="h1" className="Description">
                                                A separate Ramadhan page for schedules, Iftar/Seheri sponsor/availability, FAQ and much more
                                            </Typography>
                                        </AccordionDetails>

                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>

                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <div>
                                                <Typography className="DonatoinFormTitle" ><img src={EidianIcon} />Eidain (Eid Prayer) Page</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Typography variant="h1" className="Description">
                                                A separate Eid page with Eidain prayer schedules, do's/don'ts, FAQ and much more
                                            </Typography>
                                        </AccordionDetails>

                                    </Accordion>

                                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>

                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                            <div>
                                                <Typography className="DonatoinFormTitle" ><img src={BuildProjectIcon} />Build Project</Typography>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Typography variant="h1" className="Description">
                                                Promote new construction, renovation or expansion project with a dedicated page with URL
                                            </Typography>
                                        </AccordionDetails>

                                    </Accordion>

                                </div>
                            </Grid>


                            <Grid item md={6} sm={6} xs={12}>
                                <div className="buildProjectImg">
                                    <img src={EmbedAnythingBanner} />
                                </div>
                            </Grid>

                        </Grid>
                    </div>

                </div>
            </Container>

        </div>
    );
}


export default EmbedAnything;