import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from '../common/Header'
import Footer from '../common/Footer'
import Link from '@material-ui/core/Link';
import FacebookIcon from '../../assets/imgs/socialicons/FacebookRounded.svg';
import ShareIcon from '../../assets/imgs/socialicons/ShareIcon.svg';
import TwitterIcon from '../../assets/imgs/socialicons/TwitterRounded.svg';
import WhatsAppIcon from '../../assets/imgs/socialicons/WhatsappRounded.svg';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

import articleImg from '../../assets/imgs/articleImg.jpg';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Detailsbanner from '../../assets/imgs/BlogDetails.png';





function BlogDetails() {



    return (

        <div className="">
            <Header />

            <div className="SectionContainer">
            <div className="PageTitlepattren"></div>

                <div className="InnerPageContainer">
                <div className="InnerPageBanner">
                    <div className="InnerPageContentSection">
                        <p className="TitleSmallText">Product updates <FiberManualRecordIcon /> 5 min read</p>
                        <Typography className="articleTitle" variant="h2" gutterBottom>
                            Flutter is no longer a cross-platform framework — it is something more.
                        </Typography>
                    </div>
                </div>

                <div className="BlogDetailsBanner"><img src={Detailsbanner} /></div>

                <Container maxWidth="lg">
                    <div className="InnerPageContentSection">

                        <Grid container spacing={3}>

                            <Grid item md={12} sm={12} xs={12}>


                                <div className="autherAndSocialDetails">
                                    <div className="autherInfo">
                                        <div className="autherIcon">
                                            <PersonRoundedIcon />
                                        </div>
                                        <div className="">
                                            <h2>Sheikh Abdullah Raihan</h2>
                                            <p>March 8, 2021</p>
                                        </div>
                                    </div>
                                    <div className="SocialIcon">
                                        <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                        <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    </div>
                                </div>

                                <div className="ArticleContentsContainer">
                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Over the last few years I have written several articles showing how iOS apps built with different versions of Xcode would appear when run on iOS devices that didn’t exist when the apps were built. As a general rule, apps must build with the latest version of Xcode to opt in to seeing the native screen resolutions of new devices. Older apps would run on newer devices
                            <br /><br />but appeared as letterboxed, pillar boxed and/or scaled versions of previous device sizes. This ensured that the old apps never ran at screen resolutions that didn’t exist when they were built. In WWDC 2019: 224 Modernizing Your UI for iOS 13, the presenter discusses backward compatibility and stated:
                                  </Typography>

                                    <Typography className="articleperagraph Quots" variant="body1" gutterBottom>
                                        In the past if we introduced new hardware with a new screen size, your apps were letterboxed. Well, we’re not going to be doing that anymore either. So, if you application is built against the iOS 13 SDK, then it will always be displayed at the native full-screen resolution of the screen.
                                   </Typography>

                                    <div className="articleImg"><img src={articleImg} /></div>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1. “Design Sprints are about building MVPs faster.”
                                    </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Careful now. Design Sprints definitely speed things up considerably, but they are not about building a finished product within one week. If you go into your first Sprint with the expectation of having usable code or sending off files to a printer or manufacturer at the end of the week, you are doing it wrong.
                                    </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        1. “Design Sprints are about building MVPs faster.”
                                   </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        Careful now. Design Sprints definitely speed things up considerably, but they are not about building a finished product within one week. If you go into your first Sprint with the expectation of having usable code or sending off files to a printer or manufacturer at the end of the week, you are doing it wrong.
                                     </Typography>

                                    <div className="youtubevideo">
                                        <iframe width="100%" height="358" src="https://www.youtube.com/embed/BtUjOwNPg0Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        <FiberManualRecordRoundedIcon />
                        They offer a productive process that allows to experiment with solutions instead of procrastinating forever.
                                    </Typography>

                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        <FiberManualRecordRoundedIcon />
                        They offer a self-contained space where failure carries a low risk, no stigma and thus help propagate an experimental mindset: try things and see if they work instead of racking your brain trying to make the one, right decision (which is impossible).
                                 </Typography>

                                    <Typography className="articleperagraph ArtList" variant="body1" gutterBottom>
                                        <FiberManualRecordRoundedIcon />
                        They compress the time needed from conceptualizing solutions to testing them into a few days.
                                   </Typography>

                                    <Typography className="ArticleSubTitle" variant="h2" gutterBottom>
                                        Conclusion
                                       </Typography>

                                    <Typography className="articleperagraph" variant="body1" gutterBottom>
                                        If you have made it this far, wow. Let me wrap this up: I think a lot of issues people have with Design Sprints are founded in misunderstandings what Sprints claim to do. To be clear, Design Sprints are not the only valid approach to problem-solving and might not work for everyone. So why do I, as a designer, still think that Design Sprints are extremely valuable?
                                       </Typography>

                                </div>



                            </Grid>




                        </Grid>

                        <div className="autherAndSocialDetails BottomSocial">
                            <div className="autherInfo">
                                <div className="autherIcon">
                                    <PersonRoundedIcon />
                                </div>
                                <div className="">
                                    <h2>Sheikh Abdullah Raihan</h2>
                                    <p>March 8, 2021</p>
                                </div>
                            </div>
                            <div className="SocialIcon">
                                <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                <Link><img src={ShareIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                            </div>
                        </div>



                    </div>
                </Container>
                </div>
            </div>

            <div className="SubscribeContainer">

                <Container maxWidth="lg">
                    <div className="SubscribeContent">
                    <div className="Subscribeinfo">
                         <img src={ShareIcon} alt="Logo" className="MailIcon" />
                         <div>
                            <h2>Never miss a post</h2>
                            <p>By subscribing, you confirm you're happy for us to send you our latest articles.</p> 
                         </div>
                    </div>
                    <div className="SubscribForm">
                        <div className="GetStartForm">
                            <TextField id="outlined-basic" className="GetStartedField"  variant="outlined" placeholder="Your email address"/>
                            <Button variant="contained" className="ButtonGreenFilled">Subscribe</Button> 
                        </div>
                    </div>
                    </div>
                </Container>

            </div>

            <Footer />

        </div>
    );
}


export default BlogDetails;